import React from 'react'
import UserForm from '../components/Form/UserForm'

export default function LoginPage() {
  return (
    <>
    <UserForm />
    </>
  )
}
